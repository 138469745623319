<script lang="ts">
    export let type = 'solid';
</script>

{#if type === 'solid'}
    <svg xmlns="http://www.w3.org/2000/svg" {...$$restProps} width="15" height="24" viewBox="0 0 15 24" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 0H0V23.1818H15V0ZM5.45454 8.99084V13.4747C5.45454 13.8694 5.89048 14.1085 6.22332 13.8963L10.1651 11.3834C10.4927 11.1745 10.4674 10.6883 10.12 10.5145L6.17815 8.54363C5.8457 8.3774 5.45454 8.61915 5.45454 8.99084Z"
            fill="currentColor"
        ></path>
    </svg>
{:else}
    <svg xmlns="http://www.w3.org/2000/svg" {...$$restProps} width="15" height="24" viewBox="0 0 15 24" fill="none">
        <rect x="0.5" y="0.5" width="14" height="22.1818" rx="1.5" stroke="currentColor"></rect>
        <path
            d="M5.45454 13.4747L5.45454 8.99084C5.45454 8.61915 5.8457 8.3774 6.17815 8.54363L10.12 10.5145C10.4674
            10.6883 10.4927 11.1745 10.1651 11.3834L6.22332 13.8963C5.89048 14.1085 5.45454 13.8694 5.45454 13.4747Z"
            stroke="currentColor"
            stroke-width="0.75"
        ></path>
    </svg>
{/if}
